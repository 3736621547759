/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ShareIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22.2 2.8c0-.5-.4-.9-.9-.9-.2 0-.4.1-.4.1l-.2.1-18 6.3c-.4.2-.7.6-.8 1 0 .5.2.9.7 1.1l7.7 3.4 3.4 7.7c.2.4.6.7 1 .7.5 0 .9-.3 1-.8l6.3-18 .1-.3s.1-.2.1-.4zM6.1 9.4l10.6-3.7-5.9 5.9-4.7-2.2zm8.5 8.5l-2.1-4.8 5.9-5.9-3.8 10.7z" />
	</svg>
))
ShareIconSvg.displayName = 'ShareIconSvg'

const ShareIcon = forwardRef((props, ref) => <Icon component={ShareIconSvg} ref={ref} {...props} />)
ShareIcon.displayName = 'ShareIcon'

ShareIcon.defaultProps = {
	...Icon.defaultProps,
}
ShareIcon.propTypes = {
	...Icon.propTypes,
}

export default ShareIcon
export { ShareIconSvg }
