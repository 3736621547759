/**
 * @description Strips HTML tags from a string
 * @param {string} html
 * @returns {string}
 */
export const stripHtml = (html) => {
	// strip html tags, but <br> tags should be replaced with \n
	html = html.replace(/<br\s*\/?>/gi, '\n')
	// strip the rest of the tags
	html = html.replace(/<[^>]+>/g, '')
	return html
}
