import { stripHtml } from '@/utils'
import Link from '@bluheadless/ui/src/molecules/link/link'
import SocialShare from '@bluheadless/ui/src/organisms/social-share/social-share'
import { ImageArtDirection } from '@bluheadless/ui/src/particles/art-direction'
import { cx } from '@emotion/css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { array, node, number, object, string } from 'prop-types'
import { useIntl } from 'react-intl'
import { InnerWrapper, Root, SendIconStyled, ShareWrapper, Title } from './share-banner.styled'

const ShareBanner = ({
	title,
	_title,
	titleProps,
	ImageComponent,
	imageSources,
	imageProps,
	imageLink,
	imageLinkTarget,
	imageAlt,
	cta,
	className,
	TitleComponent,
	...props
}) => {
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
	const imageOriginalWidth = mdUp ? imageSources?.md?.originalWidth : imageSources?.xs?.originalWidth
	const imageOriginalHeight = mdUp ? imageSources?.md?.originalHeight : imageSources?.xs?.originalHeight
	const { formatMessage } = useIntl()

	return (
		<Root className={cx('ShareBanner-root', className, imageLink && 'can-hover')} {...props}>
			<InnerWrapper>
				<Link href={imageLink} target={imageLinkTarget} onClick={cta?.[0]?.onClick}>
					<ImageComponent
						src={mdUp ? imageSources?.md?.src : imageSources?.xs?.src}
						alt={imageAlt}
						originalWidth={imageOriginalWidth}
						originalHeight={imageOriginalHeight}
						sources={imageSources}
						{...imageProps}
					/>
				</Link>
				{title && (
					<Link href={imageLink} target={imageLinkTarget} onClick={cta?.[0]?.onClick}>
						<TitleComponent className="title" {...titleProps}>
							{title}
						</TitleComponent>
					</Link>
				)}
			</InnerWrapper>
			{cta?.length > 0 && (
				<ShareWrapper>
					<SocialShare
						title={stripHtml(_title)}
						url={imageLink}
						media={imageSources?.md?.src}
						buttonProps={{ label: formatMessage({ id: 'share' }), variant: 'text', StartIcon: SendIconStyled }}
					/>
				</ShareWrapper>
			)}
		</Root>
	)
}

ShareBanner.defaultProps = {
	ImageComponent: ImageArtDirection,
	TitleComponent: Title,
}
ShareBanner.propTypes = {
	title: node,
	titleProps: object,
	imageSrc: string,
	imageOriginalWidth: number,
	imageOriginalHeight: number,
	imageSources: object,
	imageProps: object,
	imageLink: string,
	imageLinkTarget: string,
	cta: array,
	className: string,
}

export default ShareBanner
