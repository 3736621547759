import ShareIcon from '@bluheadless/ui/src/atoms/icons/share-icon'
import Typography from '@bluheadless/ui/src/atoms/typography/typography'
import { ButtonStyled as SocialShareButton } from '@bluheadless/ui/src/organisms/social-share/social-share.styled'
import styled from '@emotion/styled'

export const Root = styled('div')`
	position: relative;
	a {
		text-decoration: none;
		:first-of-type {
			span {
				border-radius: calc(var(--card-border-radius) * 2);
				${({ theme }) => theme.breakpoints.up('md')} {
					border-radius: calc(var(--card-border-radius) * 5);
				}
			}
		}
	}
	&.can-hover {
		img {
			transition: transform 0.5s;
			animation-timing-function: ${({ theme }) => theme.transitions.easing.easeInOut};
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
`

export const InnerWrapper = styled('div')`
	position: relative;
`

export const Title = styled(Typography)`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	padding: ${({ theme }) => theme.spacing(1.5)};
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	line-height: ${({ theme }) => theme.typography.pxToRem(18)};
	color: ${({ theme }) => theme.palette.common.white};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(32)};
		line-height: ${({ theme }) => theme.typography.pxToRem(36)};
		padding: ${({ theme }) => theme.spacing(4, 3)};
	}
`

export const ShareWrapper = styled('div')`
	text-align: right;
	padding: ${({ theme }) => theme.spacing(1.25)};
	span {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		line-height: ${({ theme }) => theme.typography.pxToRem(24)};
		font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(3)};
	}
	${SocialShareButton} {
		border-bottom: 0;
	}
`

export const SendIconStyled = styled(ShareIcon)`
	font-size: ${({ theme }) => theme.typography.pxToRem(24)} !important;
`
